import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//components
// import PanelFoundation from "../../components/panels/PanelFoundation";
import Hero from "../../components/panels/Hero";
import ComponentHeader from "../../components/panels/ComponentHeader";
import Switch from "../../components/panels/Switch";
import ListItem from "../../components/panels/ListItem";
import Approach from "../../components/panels/Approach";
import MoreCards from "../../components/panels/MoreCards";
import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";

//styles
import s from "./artificial-intelligence.module.scss";
// import heroServicesStyles from "../../components/panels/hero-insights";
// import lsStyles from "../../components/panels/list-item.module.scss";

const AIAndAnalyticsPage = ({ location, data }) => {
  const {
    SEO: seo,
    hero,
    overview_heading,
    overview_description,
    ai_overview_image,
    benefits,
    Enhancements
    // Cognitive,
    // document_understanding_overview,
    // document_understanding_cards
  } = data.strapiPsAi;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `Artificial Intelligence (AI) Solutions Company | Solutions`,
          description: seo.description
        }}
      />
      <Hero
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-cloud.mp4"}
        heading={hero[0].main_heading}
        icon={hero[0].service_icon.childImageSharp.fixed}
      />
      {/* <div style={{ marginTop: "40px" }}></div> */}
      <Switch
        contents={[
          {
            image: <Image fluid={ai_overview_image.childImageSharp.fluid} />,
            heading: overview_heading,
            content: (
              <ReactMarkdown
                source={overview_description}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          }
        ]}
      />

      <ComponentHeader
        rootClassName={"bg-light"}
        textClassName={"text-primary"}
        text={"Our Capabilities"}
      />

      <ListItem
        rootClassName={s.purpleBackground}
        columnsPerRow={2}
        isCard
        hasCircle
        circleBorderColor={`var(--primary)`}
        contents={benefits.map((b, i) => ({
          image: (
            // <div className={lsStyles.iconCircle}>
            <Image fixed={b.card_image.childImageSharp.fixed} />
            // </div>
          ),
          heading: b.heading,
          content: (
            <ReactMarkdown
              source={b.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />

      {/* <ComponentHeader
        rootClassName={"bg-white"}
        textClassName={"text-primary"}
        text={"Document Understanding"}
        id={"rpa-doc"}
      />
      <Switch
        contents={document_understanding_overview.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: <ReactMarkdown source={cog.content} escapeHtml={false} />
          };
        })}
      />
      <ListItem
        columnsPerRow={3}
        isCard
        // hasCircle

        // circleColor={"white"}
        // circleBorderColor={"var(--purple)"}
        itemClassName={`${s.card}`}
        contents={document_understanding_cards.map((oc, i) => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: <ReactMarkdown source={oc.content} escapeHtml={false} />
        }))}
      /> */}
      <ComponentHeader
        rootClassName={"bg-light"}
        textClassName={"text-primary"}
        text={"AI Solutions Enhancements"}
      />
      <ListItem
        columnsPerRow={2}
        // hasCircle
        // circleColor={"white"}
        // circleBorderColor={"var(--purple)"}
        contents={Enhancements.map((e, i) => ({
          image: (
            <Image
              className={s.imgWrapper}
              fixed={e.card_image.childImageSharp.fixed}
            />
          ),
          heading: e.heading,
          content: (
            <ReactMarkdown
              source={e.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />
      {/* <ComponentHeader
        rootClassName={"bg-white"}
        textClassName={"text-primary"}
        text={"Cognitive Assistants"}
        id={"rpa-cogn"}
      />

      <Switch
        evenClassName={`bg-primary text-white`}
        oddClassName={`bg-white text-primary`}
        contents={Cognitive.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: <ReactMarkdown source={cog.content} escapeHtml={false} />
          };
        })}
      /> */}
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Our Approach to AI Solutions"}
      />
      <Approach />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"More Solutions"}
      />
      <MoreCards
        rootClassName={`bg-light`}
        itemClassName={`col-lg-4 col-md-6 `}
        cards={["Chatbots", "IntelligentAutomation", "RPA"]}
      />
      {/* <OtherIndustries
        services={["cloud", "it-consulting", "rpa", "virtual-assistant"]}
      /> */}
      {/* <div
        className={`${layoutStyle.containerFluid} ${heroServicesStyles.leftHeading}`}
        style={{ marginTop: "4rem" }}
      ></div>
      <SwitchComponent
        contents={[
          {
            image: <Image fluid={ai_overview_image.childImageSharp.fluid} />,
            heading: overview_heading,
            content: (
              <ReactMarkdown source={overview_description} escapeHtml={false} />
            )
          }
        ]}
      />
      <ComponentHeader
        bgColor={"light-gray"}
        textColor={"purple"}
        text={"Your Benefits"}
      />
      <ListItem
        columnsPerRow={2}
        isCard
        bgClassName={s.purpleBackground}
        contents={benefits.map((b, i) => ({
          image: (
            <div className={lsStyles.iconCircle}>
              <Image fixed={b.card_image.childImageSharp.fixed} />
            </div>
          ),
          heading: b.heading,
          content: <ReactMarkdown source={b.content} escapeHtml={false} />
        }))}
      />
      
      <ComponentHeader
        bgColor={"white"}
        textColor={"purple"}
        text={"Document Understanding"}
        id={"rpa-doc"}
      />
      <SwitchComponent
        contents={document_understanding_overview.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: <ReactMarkdown source={cog.content} escapeHtml={false} />
          };
        })}
      /> 
      <ListItem
        columnsPerRow={3}
        isCard
        hasCircle

        circleColor={"white"}
        circleBorderColor={"var(--purple)"}
        itemClassName={`${s.card}`}
        contents={document_understanding_cards.map((oc, i) => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: <ReactMarkdown source={oc.content} escapeHtml={false} />
        }))}
      />
      <ComponentHeader
        bgColor={"grey"}
        textColor={"purple"}
        text={"Enhancements"}
      />
      <ListItem
        columnsPerRow={2}
        contents={Enhancements.map((e, i) => ({
          image: (
            <div className={lsStyles.iconCircle}>
              <Image
                className={s.imgWrapper}
                fixed={e.card_image.childImageSharp.fixed}
              />
            </div>
          ),
          heading: e.heading,
          content: <ReactMarkdown source={e.content} escapeHtml={false} />
        }))}
      />
      <ComponentHeader
        bgColor={"white"}
        textColor={"purple"}
        text={"Cognitive Assistants"}
        id={"rpa-cogn"}
      />
      <SwitchComponent
      evenBgColor={"purple"}
      evenTextColor={"white"}
      oddBgColor={"white"}
      oddTextcolor={"purple"}
        contents={Cognitive.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: <ReactMarkdown source={cog.content} escapeHtml={false} />
          };
        })}
      />
      <ComponentHeader
        bgColor={"light-gray"}
        textColor={"purple"}
        text={"Our Approach"}
      />
      <Approach />
      <OtherServices
        services={["cloud", "it-consulting", "rpa", "virtual-assistant"]}
      /> */}
    </>
  );
};

export default AIAndAnalyticsPage;

export const pageQuery = graphql`
  {
    strapiPsAi {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      overview_heading
      overview_description
      ai_overview_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benefits {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      Enhancements {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

// Cognitive {
//   heading
//   content
//   row_switch_image {
//     childImageSharp {
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }

// document_understanding_overview {
//   heading
//   content
//   row_switch_image {
//     childImageSharp {
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }

// document_understanding_cards {
//   heading
//   content
//   card_image {
//     childImageSharp {
//       fixed(width: 100) {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// }
